.topbarContainer {
    width: 100%;
    background-color: #050A30;
    display: flex;
    flex-direction: column;
    padding: 5px 10px;
    box-shadow: 0px 20px 95px -4px rgba(0, 0, 0, 0.1);

}

.topbarfirstwrapper {
    display: flex;
    align-items: center;
    width: 100%;
}

.topbarsecondwrapper {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.mainlogo {
    width: 205px;
    height: 72px;
    margin-left: 20px;
    border-radius: 6px;
    padding: 5px;
}

.topbarLeft {
    flex: 2;
}

.topbarCenter {
    flex: 2;
    padding-left: 20px;
    margin-left: auto;
    margin-right: auto;
}

.topbarRight {
    flex: 2;
    display: flex;
    align-items: center;
    color: white;
}

.logo {
    font-size: 26px;
    margin-left: 20px;
    font-weight: bold;
    color: white;
    font-family: Klavika;
}

.searchbar {
    width: 90%;
    height: 40px;
    background-color: white;
    border-radius: 8px;
    display: flex;
    align-items: center;
}

.searchIcon {
    font-size: 20px !important;
    font-weight: 900 !important;
    margin-right: 10px;
}

.searchInput {
    width: 90%;
    border: none;
    font-size: 15px;
    padding: 0 0 0 25px;
}

.searchInput:focus {
    outline: none;
}

.topbarLinks {
    display: flex;
    align-items: center;
}

.topbarLink {
    margin-right: 20px;
    font-size: 17px;
    cursor: pointer;
    font-weight: 500;
    color: rgb(239, 239, 239);
}


.topbarlinklogin {
    padding: 10px 25px 10px 25px;
    border-radius: 50px 0 0 50px;
    background-color: rgb(107, 107, 107);
    font-size: 17px;
    font-weight: 500;
    cursor: pointer;
}

.topbarlinkregister {
    padding: 10px 25px 10px 25px;
    border-radius: 50px;
    background-color: rgb(30, 30, 30);
    margin-left: -20px;
    font-size: 17px;
    font-weight: 500;
    cursor: pointer;
}

.topbarIcons {
    display: flex;
    align-items: center !important;
    margin-left: auto;
    margin-right: 25px;
}

.profileimg {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
    margin-left: 17px;
    zoom: 10px;
    border: rgb(30, 24, 116) 1px solid;

}

.profileimg:hover {
    width: 48px;
    height: 48px;
}

.btn-secondary {
    background-color: inherit;
    color: rgb(239, 239, 239);
    font-weight: 500;
    border: none;
}

.btn-secondary:hover {
    border-bottom: 4px solid red;
}

.btn-secondary.active {
    border-bottom: 4px solid red;
}


.headerMenuList {
    align-items: stretch;
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
}

.headerMenuListItems {
    cursor: pointer;
    display: flex;
    font-size: 16px;
    font-size: var(--font-size-metadata);
    font-weight: 500;
    letter-spacing: .02em;
    line-height: 22px;
    line-height: var(--line-height-metadata);
    list-style-type: none;
}

.topbarLink:hover {
    border-bottom: 4px solid red;
}

.topbarLink.active {
    border-bottom: 4px solid red;
}

