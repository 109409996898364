.card-group {
    padding: 20px 30px;
    grid-gap: 30px;
    grid-template-columns: 200px 200px 200px;
}

.exercisemainwrapper {
    background-color: #2a2a2a;
    margin: 20px 20px 20px 20px;
    border-radius: 20px;
    box-shadow: 0px 3px 100px 21px rgba(0, 0, 0, 0.1);
}

.card {
    border-radius: 15px !important;
    box-shadow: 0px 3px 100px 21px rgba(0, 0, 0, 0.1) !important;
}

.card-img-top {
    object-fit: contain;
    height: 300px;
}

h5 {
    font-weight: 900;
    font-size: 25px;
}

.card-body {
    flex: 1 1 auto;
    padding: 0 1.25rem 1rem 1.25rem;
}

.card-title {
    font-size: 20px;
    padding: 15px 0 15px 25px;
    border-radius: 30px;
    background-color: #3a3a3a;
    color: white;
}

.mainpageheaderH2 {
    color: white;
    margin-bottom: 10px;
}