.mainWrapper {
    display: flex;
    flex-direction: column;
}

.mainWrapperBox {
    display: flex;
    height: fit-content;
    width: inherit;
    margin-left: 7%;
    margin-right: 7%;
    margin-top: 60px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    background: rgb(0, 0, 0);
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(5, 10, 48, 1) 0%, rgba(126, 42, 162, 1) 100%);
    border-radius: 0px 50px 0px 50px;
    transition: transform .2s;
}

.mainWrapperBox:hover {
    -ms-transform: scale(1.1);
    /* IE 9 */
    -webkit-transform: scale(1.1);
    /* Safari 3-8 */
    transform: scale(1.1);
}

.mainWrapperBoxLeftContent {
    flex: 1.5;

}

.mainWrapperBoxRightContent {
    flex: 2;
    align-items: center;
    overflow: hidden;
}

#mainWrapperBoxLeftContentHeading {
    font-weight: 700;
    font-size: 48px;
    color: rgb(255, 255, 255);
    opacity: 0.8;
    margin: 100px 0 50px 80px;
}

#mainWrapperBoxLeftContentparagraph {
    font-size: 18px;
    font-weight: 400;
    color: rgb(255, 255, 255);
    opacity: 0.8;
    margin: 0 10px 0 80px;
    letter-spacing: 1px;
}


.wrapperImage {
    width: 500px;
    height: 500px;
    margin-left: 10%;
    border: none;

}