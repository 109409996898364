.bmimainwrapperheading {
    font-size: 48px;
    font-weight: 800;
    color: white;
    padding: 70px 0px 10px 40px;
    /* margin: 0 50px; */
    /* border-radius: 20px; */
    background: rgb(148, 187, 233);
    background: -moz-linear-gradient(90deg, rgba(148, 187, 233, 1) 5%, rgba(238, 174, 202, 1) 60%);
    background: -webkit-linear-gradient(90deg, rgba(148, 187, 233, 1) 5%, rgba(238, 174, 202, 1) 60%);
    background: linear-gradient(90deg, rgba(148, 187, 233, 1) 5%, rgba(238, 174, 202, 1) 60%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#94bbe9", endColorstr="#eeaeca", GradientType=1);
}

.bmicalculatorwrapper{
    margin: 50px auto 0px;
    max-width: 1200px;
    background: rgb(244 225 225);
    border-radius: 20px;
    padding: 15px;
}

.bmicalculatorcontainer{
    display: flex;
    background: rgb(255, 255, 255);
    border-radius: 13px;
    
}

.bmicalculatorwrapperleft{
    width: 55%;
    padding: 50px 46px;
}

.bmicalculatorwrapperright{
    width: 45%;
    padding: 50px 50px;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.bmicalculatorpromotion {
    font-size: 18x;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 38px 52px 30px;
}

.bmicalculatorinputsbox{
 display: flex;
 flex-direction: column; 
 margin: 15px 0;

}

#bmicalculatorinputslabel{
font-size: 20px;
color: rgb(0, 0, 0);
font-weight: 700;
}

#bmicalculatorinputs{
    background-image: linear-gradient(-63deg, rgb(125, 144, 226) 5%, rgb(142, 160, 239) 100%);
    background-color: rgb(228, 234, 252);
    background-repeat: no-repeat;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px inset;
    border-radius: 3.5px;
}

.bmicalculatortotal{
    font-size: 25px;
    font-weight: 700;
    margin-top: 55px;
}
/* .dFjwfa {
    appearance: none;
    width: 100%;
    height: 6px;
    color: rgb(0, 0, 0);
    outline: none;
    margin-top: 26px;
    margin-bottom: 30px;
    cursor: pointer;
    background-image: linear-gradient(-63deg, rgb(125, 144, 226) 5%, rgb(142, 160, 239) 100%);
    background-color: rgb(228, 234, 252);
    background-repeat: no-repeat;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px inset;
    border-radius: 3.5px;
} */
