.contactmainWrapper {
    width: 100%;
    min-height: 100vh;
    background-color: #000000;
    display: flex;
    flex-direction: column;
}

.contactmainWrapperHeading {
    width: fit-content;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    font-size: 3rem;
    font-weight: 600;
    color: white;
    opacity: 0.9;

}

.contactmainWrapperForm {
    min-height: 500px;
    background-color: #111112;
    margin: 0 80px 0 80px;
    border-radius: 15px;
    display: flex;
    overflow: hidden;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
        rgba(17, 17, 26, 0.1) 0px 8px 24px,
        rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

.contactmainWrapperFormLeft {
    width: 50%;
    height: 100%;
    /* padding: 50px 0 0 50px;  */
    background-color: rgb(36, 36, 36);
    border-radius: 15px 0 0 15px;
}

.contactmainWrapperFormRight {
    width: 50%;
    height: 550px;
    border-radius: 0 15px 15px 0;
    padding: 2% 0 0 10%;
}

.contactformAdressMap {
    width: 100%;
    height: 550px;
    filter: grayscale(100%) invert(92%) contrast(83%);
    color: white;
}

#contactformHeading {
    color: white;
    font-size: 26px;
    letter-spacing: 0.65px;
    margin: 10px 0 30px 0;
    
}

#contactForm_label {
    font-size: 16px;
    color: wheat;
    margin: 3px;
    padding: 0;
}

#contactForm_fname,
#contactForm_email,
#contactForm_number {
    font-size: 16px;
    padding: 0 0 0 10px;
    width: 55%;
    height: 30px;
    border-radius: 7px;
    border: none;
    margin-bottom: 15px;
}

#contactForm_message{
    font-size: 16px;
    padding: 5px 10px 5px 10px;
    width: 55%;
    height: 60px;
    border-radius: 7px;
    border: none;
    /* margin-bottom: 15px; */
}

#contactForm_Submit {
    width: 35%;
    height: 40px;
    margin: 25px 20px 0 0;
    color: black;
    font-size: 20px;
    font-weight: 800;
    background-color: rgb(255, 225, 0);
    border-radius: 10px;

}

::placeholder {
    font-size: 14px;
    padding: 0 0 0 10px;
}

#contactForm_Reset {
    width: 17%;
    height: 40px;
    margin: 25px 0px 0 0;
    color: black;
    font-size: 20px;
    font-weight: 800;
    background-color: rgb(253, 253, 253);
    border-radius: 10px;
}