.yogamenuWrapper{
background-color: #2a2a2a;
margin: 20px 20px 20px 20px;
border-radius: 20px;
box-shadow: 0px 3px 100px 21px rgba(0, 0, 0, 0.1);
}

.yogamenuheader{
color: white;
}

.card-group {
    padding: 20px 50px;
    grid-gap: 30px;
    display: grid !important;
    grid-template-columns: auto auto auto auto;
}

.card-title{
    margin-top: 20px;
    font-size: 20px;
    padding: 15px 0 15px 25px;
    border-radius: 30px;
    background-color: #3a3a3a;
    color: white;
}

.card-text{
    margin-left: 10px;
}

.card {
    border-radius: 15px !important;
    box-shadow: 0px 3px 100px 21px rgba(0, 0, 0, 0.1) !important;
    cursor: pointer;
    transition: transform .2s;
}

.card-img-top{
    border-radius: 15px 15px 0 0 !important;  
}



.card:hover{
    -ms-transform: scale(1.1); 
    -webkit-transform: scale(1.1);
    transform: scale(1.1); 
}


/* #homepageExpertButton {
    margin-top: 50px;
    padding: 20px 65px;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    font-size: 24px;
    font-weight: 800;
    font-family: 'Inter', sans-serif;
    letter-spacing: 0.4px;
    border-radius: 40px;
    cursor: pointer;
    transition: transform .2s;
}

#homepageExpertButton:hover {
    -ms-transform: scale(1.2);
   
    -webkit-transform: scale(1.2);
   
    transform: scale(1.2);
}

#homepageExpertButton:focus {
    outline: none !important;
    border: none !important;
} */