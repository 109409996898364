.mainwrapper {
  width: 100%;
  height: auto;
  background-color: #ffffff;
}

.flexbox {
  display: grid;
  width: 90%;
  padding: 2%;
  grid-template-columns: auto auto auto;
  margin-left: 5%;
  margin-right: 5%;
}

.grid-item {
  min-width: 200px;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  font-size: 30px;
  text-align: center;
  margin: 20px;
  padding: 10px;
  border-radius: 20px;
  transition: transform .2s;
}

h2 {
  font-weight: 600;
  text-align: center;
  padding-top: 50px;
  font-size: 36px;
  font-family: Arial, Helvetica, sans-serif;
}

h4 {
  font-size: 20px;
  color: black;
  opacity: 0.9;
  font-weight: 700;
  margin-top: 10px;
}

h6 {
  font-size: 15px;
  font-weight: 500;
  color: rgb(255, 0, 0);
}

.grid-item:hover {
  -ms-transform: scale(1.2);
  /* IE 9 */
  -webkit-transform: scale(1.2);
  /* Safari 3-8 */
  transform: scale(1.2);
}

.teamprofilePic {
  width: 120px;
  height: 120px;
  /* object-fit: cover; */
  border-radius: 50%;
  border: 2px solid rgb(220, 246, 252);
}

.teamsocialIcon {
  width: 30px;
  height: 30px;
  border: none;
  cursor: pointer;
  margin-right: 6px;
}

hr {
  width: 50%;
  height: 10px;
  margin-right: 25%;
  margin-left: 24%;
	border: 0;
	box-shadow: 0 10px 10px -10px #ff0000 inset;
}