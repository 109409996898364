.mainwrapper{
    background-color: rgb(13, 13, 13);
}

.mainwrapperbox{
    display: flex;
}

.exercisewrapperbox{

}

.miniexercisewrapperHeading{
font-size: 20px;
font-weight: 700;
color: white;
padding: 20px 0 0 42px;
}

.miniexercisewrapperBox{
    display: flex;
    padding-left: 25px;
    align-items: center;
    overflow: hidden;
}

.nextpageexerciseArrow{
    font-size: 150px;
    font-weight: 300;
    color: rgb(217, 213, 213);
    margin-left: 20px;
}