.mainWrapper {
    display: flex;
    flex-direction: column;
}


.mainWrapperBox {
    display: flex;
    height: fit-content;
    width: inherit;
    margin-left: 7%;
    margin-right: 7%;
    margin-top: 40px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    background: #ffffff;
    border-radius: 0px 50px 0px 50px;

}

.mainWrapperIntro {
    width: 100%;
    min-height: 92vh;
    position: relative;
    padding: 0px 100px 200px 100px;
    text-align: center;
    background-image: url("https://www.imghippo.com/images/1695144790.jpg");
    /* background-image: url("/public/assets/onlinecontent/homebackg.png"); */
}

#homepageExpertButton {
    margin-top: 50px;
    padding: 20px 65px;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    font-size: 24px;
    font-weight: 800;
    font-family: 'Inter', sans-serif;
    letter-spacing: 0.4px;
    border-radius: 40px;
    cursor: pointer;
    transition: transform .2s;
}

#homepageExpertButton:hover {
    -ms-transform: scale(1.2);
    /* IE 9 */
    -webkit-transform: scale(1.2);
    /* Safari 3-8 */
    transform: scale(1.2);
}

#homepageExpertButton:focus {
    outline: none !important;
    border: none !important;
}

#mainWrapperIntroHeading {
    color: rgb(248, 248, 248);
    font-family: gilroy-bold;
    font-size: 120px;
    font-weight: 800;
    letter-spacing: -4.5px;
    line-height: 120px;
    margin-top: 18vh;
    padding: 0;
}

#mainWrapperIntroparagraph {
    color: rgb(248, 248, 248);
    margin-top: 30px;
}