.mainwrapper1 {
    width: 100%;
    background-color: #ffffff;
}

.mainwrapperBox {
    background-color: white;
    width: 80%;
    margin: 0 10% 0 10%;
    padding: 5%;
    border-radius: 10px;
}

.aboutHeading {
    font-weight: 600;
    text-align: center;
    padding-top: 50px;
    font-size: 36px;
    font-family: Arial, Helvetica, sans-serif;
}

.mainwrapperBoxHeading {
    text-align: center;
    margin-bottom: 30px;
}

.mainwrapperBoxContent {
    text-align: left;
    padding: 0 100px 0 100px;

}

#basicinfoH3 {
    font-size: 42px;
    font-weight: 600;
    margin-bottom: 50px;
    margin-left: 100px;
    text-align: left;
    
}

hr {
    width: 50%;
    height: 10px;
    margin-right: 25%;
    margin-left: 24%;
    border: 0;
    box-shadow: 0 10px 10px -10px #ff0000 inset;
}