.maincardWrapper {
    display: flex;
    flex-direction: column;
    background-color: #21232b;
}

.singleexercisecard {
    width: 60%;
    height: 700px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}

.singleexercisecardImage {
    height: 50%;

}

.singleexercisecardBody {
    height: 50%;
    background-color: rgb(255, 255, 255);
    border-radius: 0 0 15px 15px;
}

.singleexercisecardinfo {
    padding: 20px 0 20px 80px;
    background-color: white;
    border-radius: 5px;
    /* border: 0.1px #aaa7a7 solid; */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.singleexercisecardsteps {
    padding: 1.5rem;
    margin-top: 10px;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    /* border: 0.1px #aaa7a7 solid; */
    overflow: auto;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

ol {
    list-style: none;
    counter-reset: steps;
}

ol li {
    counter-increment: steps;
    margin-bottom: 2px;
}

ol li::before {
    content: counter(steps);
    margin-right: 0.5rem;
    background: #a304ff;
    color: white;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: inline-grid;
    place-items: center;
    line-height: 2em;
}