.footerContainer {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    background-color: #02030c;
    width: 100%;
}

.footerContainerTop {
    background-color: #02030c;
    color: white;
    display: flex;
    flex-direction: row;
    position: relative;
    padding-top: 40px;
    padding-bottom: 25px;
    box-shadow: 0px 0px 62px -45px #03061a;
}

.footerBottomContainer {
    background-color: #03061a;
    color: white;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 60px 0 20px 0;
    box-shadow: 0px 0px 62px -45px #03061a;
}

.footerContainerLeft {
    display: flex;
    flex-direction: column;
    flex: 1;
    opacity: 0.7;
    margin-left: 10%;
}

.footerContainerCenter {
    display: flex;
    flex: 1;
    flex-direction: column;
    opacity: 0.7;
}

.homecopyright {
    margin-left: auto;
    margin-right: auto;
    opacity: 0.8;
    margin-bottom: 50px;
}


.footerContainerRight {
    display: flex;
    flex: 1;
    flex-direction: column;

    opacity: 0.7;
}

.footerLeftHeading,
.footerCenterHeading,
.footerRightHeading {
    font-size: 23px;
    font-weight: 700;
    margin: 0 0 9px 15px;
}


ul.footerCenterMenu,
ul.footerLeftMenu {
    cursor: pointer;
}

ul.footerRightMenu {
    cursor: pointer;
    list-style: none;
}

.footerCenterMenuItems,
.footerLeftMenuItems,
.footerRightMenuItems {
    cursor: pointer;
    margin-bottom: 5px;
}

.footerBoxSocial {
    display: flex;
    align-items: center;
}

ul.footerBoxSocial {
    list-style: none;
    margin-left: auto;
    margin-right: auto;
}

.homesubscribesection {
    display: flex;
    background-color: #02030c;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}

#home_subscribe_input {
    width: 350px;
    height: 40px;
    border-radius: 7px;
    border: none;
    margin-right: 20px;
    padding: 0 0 0 15PX;
    background-color: #e6e6e6;
}

#homesubscribesubmit{
    width: 150px;
    height: 40px;
    border-radius: 7px;
    border: none;
    color: rgb(255, 0, 0);
    font-size: 20px;
    font-weight: 800;
    cursor: pointer;
}

#footerSocialItems {
    margin-right: 15px;
    align-items: center;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border: none;
}